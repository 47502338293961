@import "../../styles/colors";

.project-popup {
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 15px;

    .ant-modal-content {
        border-radius: 15px !important;
        overflow: hidden;

        .bootstrap-card-round {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-top-left-radius: 15px !important;
            border-top-right-radius: 15px !important;
        }
    }

    .ant-modal-footer {
        text-align: left;
        width: 100%;
    }

    .ant-input-number-handler-wrap {
        width: 0;
    }
    .project-meta-heading {
        font-weight: 600;
    }
}
@import "../../styles/function";
@import "../../styles/colors";

.mr-2 {
  margin-right: 0.5em;
}

.mt-2 {
  margin-top: 1.5em !important;
}

.ml-auto {
  margin-left: auto !important;
}

.w-max {
  max-width: 300px;
}

.tab-header {
  border-bottom: 1px solid $border-light-gray;
  margin-bottom: 15px;
}

.registration {
  // margin-top: 50px;
  margin-bottom: 50px;

  .registration-title {
    padding-left: 15px;
  }

  .form-wrapper {
    background-color: $white;
    box-shadow: 0px 3px 23px -7px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 15px;

    .title {
      padding: 15px;
      margin: 0;
      margin-bottom: 20px;
      background-color: $primary-color;
      color: $white;

      svg {
        margin-right: 10px;
      }
    }

    .form-content {
      padding: 20px;

      .form-error {
        margin: 15px auto;
        text-align: center;
        color: $error-color;
        transition: all 0.2s ease;
      }

      .consents_err {
        text-align: left !important;
      }

      .sec_box {
        margin-bottom: 1%;
      }
    }

    .btn-submit {
      float: right;
    }

    .ant-radio-button-wrapper {
      display: inline-block;
    }

    .consent_bk {
      margin-bottom: 4%;
    }
  }

  .coverages-wrapper,
  .group-field-wrapper {
    .field-list {
      padding: 0 20px;

      .phn_verify {
        display: inline-flex;
        width: 100%;
        align-items: center;
        vertical-align: middle;

        button {
          margin-right: 5%;
        }
        .form-field {
          margin: 0px !important;
          padding-top: 5px;
        }
      }
    }

    .mb-0 {
      margin-bottom: 0px;

      label {
        padding: 0px;
      }
    }
  }

  .coverage-terms,
  .duration-in-period {
    .field {
      display: flex;

      .form-field {
        width: 150px;

        &:last-of-type {
          margin-left: 20px;
        }
      }
    }
  }
}

.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;

  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }

  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }

  .error {
    color: #9e2146;
  }
}

.review {
  .ant-card-bordered {
    margin-bottom: 15px;
  }

  .review-field {
    margin-bottom: 5px;
    word-break: break-word;

    .review-field-title {
      font-weight: bold;
      margin-right: 10px;

      span {
        display: inline-block;
      }
    }

    .image-review {
      width: 100%;
      margin: 5px;
    }
  }

  .single-list {
    display: inline-block;
  }

  ul {
    margin: 0;
    padding-left: 10px;

    & > li {
      padding: 15px;

      .list-title {
        font-weight: bold;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
      }
    }
  }

  ul.review-contract-img {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    & > li {
      margin: 0 10px;
      padding: 0;
      list-style-type: none;

      img {
        width: 120px;
        height: 108px;
      }
    }
  }

  .consent {
    width: 100%;

    .section {
      margin: 5% !important;
      padding: 0px !important;
      width: auto;
    }
  }
}

.saved-card {
  position: relative;
  width: 350px;
  margin: auto;
  border: 1px solid $gray80;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.2);

  // .saved-card-date {
  //   // text-align: right;
  // }

  .saved-card-number {
    margin: 20px 0px;

    span::after {
      content: "-";
      margin: 0 19px;
    }

    span:last-of-type::after {
      content: "";
      margin: 0;
    }
  }

  .card-action {
    text-align: right;

    button {
      &:hover,
      &:focus,
      &:active {
        color: $primary-color;
        border-color: $primary-color;
      }
    }
  }

  @include media(mobile) {
    width: 270px;

    .saved-card-number {
      span::after {
        content: "-";
        margin: 0 5px;
      }
    }
  }
}

.select-card {
  text-align: center;

  label.ant-radio-wrapper {
    display: inline-block;
  }
}

.payment-success {
  padding: 15px;
  margin-top: 5%;
  border: 1px solid $gray80;
  background-color: $white;

  .detail {
    text-align: center;
    margin: 50px 0;

    i svg {
      width: 150px;
      height: 150px;
      margin-bottom: 10px;
      color: $primary-color;
    }
  }
}

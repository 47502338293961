@import "../../styles/colors";
@import "../../styles/function";

$border-color: #929292;

.twr-card {
  width: 350px;
  min-height: 100px;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-radius: 5px;
  border: 1px solid #d1d1d1;

  &:hover {
    box-shadow: 0 0 10px -2px rgba($color: #000000, $alpha: 0.4);
  }

  .ant-rate-star {
    margin-right: 5px !important;
  }

  .detail {
    padding: 10px;
    bottom: 0;
    width: 100%;
    background-color: #ffffffc9;
    color: #000000;

    .owner-detail {
      display: flex;
      margin-bottom: 10px;

      .info {
        word-break: break-word;
        font-size: 18px;
        a {
          color: #000000;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .twr-card {
    width: 330px;
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .twr-card {
    width: 315px;
    max-width: 100%;
  }
}

.btn-primary {
    background-color: $primary-color;
    color: $white;
    border: none;

    &:hover, &:active, &:focus {
        background-color: $primary-dark-color;
        color: $white;
        opacity: .9;
    }
}

.btn-default {
    background-color: $default-btn-bg-color;

    &:hover, &:focus {
        background-color: $default-btn-bg-hover-color;
        color: $default-btn-color;
        border-color: $default-btn-bg-hover-color;
        opacity: .9;
    }
}

.ant-btn-default {
    background-color: $white;

    &:hover, &:active, &:focus {
        color: $default-color;
        border-color: $default-color;
        opacity: .9;
    }
}

// Social button
.btn-facebook {
    background-color: $facebook-bg;
    color: $white;

    &:hover, &:active, &:focus {
        color: $facebook-bg;
        border-color: $facebook-bg;
    }
}

.btn-google {
    background-color: $google-bg;
    color: $white;

    &:hover, &:active, &:focus {
        color: $google-bg;
        border-color: $google-bg;
    }
}

.btn-linkedin {
    background-color: $linked-bg;
    color: $white;

    &:hover, &:active, &:focus {
        color: $linked-bg;
        border-color: $linked-bg;
    }
}

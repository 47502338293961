.career_container {
    width:60%;
    margin:40px auto;
}

.career-description {
    padding:10px;
}

.career-span-style {
    display:block;
    word-wrap:break-word;
    line-height: 1.5rem;
    font-size: 16px;
    letter-spacing: 0.4px;
}
.career-manuals-h4 {
    font-size: 1.8rem;
    line-height: 110%;
    margin: 1.14rem 0 .912rem 0;
    color: #000000;
    font-weight: 400;
    letter-spacing: 1.6px;
}

.page-title {
    text-align: center;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1.2rem;
    margin: 0px 0px 45px 0px;
}

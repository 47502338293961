.mb-0 {
  margin-bottom: 0px !important;
}

.form-field {
  margin-bottom: 15px;

  &.field-error {
    border-color: $error-color;
  }

  &.file-upload-field {
    .upload-file-preview {
      width: 130px;
      position: relative;
      border: 1px dashed #d9d9d9;
      border-radius: 5px;
      padding: 5px;
      height: 130px;

      img {
        width: 100%;
      }

      svg {
        position: absolute;
        top: 5px;
        right: 5px;
        background: #fff;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 50%;
        z-index: 10;
        cursor: pointer;
      }
    }
  }

  .file-link {
    margin: 15px 0;
    text-align: center;

    i.remove {
      cursor: pointer;
      margin-left: 20px;
    }
  }

  label {
    font-weight: bold;
    padding-bottom: 5px;
    display: block;

    .required-label {
      color: $error-color;
    }

    .tooltip-icon {
      float: right;
    }
  }

  .error {
    color: $error-color;
    margin-top: 3px;
    display: block;
    transition: all 0.2s ease;
    opacity: 1;
    height: auto;
    padding-right: 5%;
  }

  .no-error {
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin-top: 0;
  }

  .ant-input-number {
    width: 100%;
  }

  .checkbox {
    display: inline;
  }
}

.checkbox {
  label {
    font-weight: bold;
    padding-bottom: 5px;
    display: flex;
  }
}

.field-list-label,
.field-label {
  font-weight: bold;
  padding-bottom: 5px;
  display: block;

  sup {
    color: $error-color;
  }
}

.field-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

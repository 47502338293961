@import "../../styles/_colors.scss";

.drawer-close {
    display: none;
    width: 0px;
}

.drawer-open {
    width: 80vw;
    height: 100vh;
    transition: width 1s ease;
    background: rgba(255, 255, 255, 0.926);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;

    ul {
        li {
            padding: 15px 15px;
            border-bottom: 1px rgb(211, 211, 211) solid;

            a {
                color: #000;
                font-size: 16px;
            }
        }
    }
}

@import '../../styles/colors';

.social-link-icons {
    width : 30px !important;
    height: 30px !important;
}

.nav-btn :hover {
    color: $primary-color;
}

.nav-btn {
    color: gray;
}

.pickup-locations-modal {
    .ant-modal-content{
        border-radius: 15px;
    }
    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
}

.pro-profile-card :hover{
    box-shadow: 0px 0px 5px 0px #3a393977;
}

.pro-profile-card > .ant-row :hover{
    box-shadow: none;
}

.contact-form-header {
    margin-bottom: 0px;
    padding: 10px;
    background-color: #c1395f;
    color: #fff;
}
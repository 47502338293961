// Responsive Media Query

@media (min-width: 576px){
    
    .footer {
        padding: 80px 12%;
        padding-bottom: 2%;
    }
    
    .mid-sec .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    
    .footer .m_order_2{
        order: 0!important;
    }
}

@media (min-width: 768px){
    .mid-sec .col-md-4 {
        flex: 0 0 33.33333% ;
        max-width: 33.33333% ;
    }
}

@media (min-width: 992px){
    
    .mid-sec .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    
    .mid-sec .col-lg-2 {
        flex: 0 0 16.66667% ;
        max-width: 16.66667% ;
    }
    
    .mid-sec .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-1{
        float: 0 0 8.3333333%s;
    }

}

@media (min-width: 993px){
    .mid-sec  h1 h2 {
        font-weight: 700;
        color: #0a1647;
        line-height: 1.5;
    }
    
    .mid-sec h2 {
        font-size: 34px;
    }
}
 
@media (min-width: 1200px){
    .col-xl-2{
        flex: 0 0 16.6666667%;
    }
}
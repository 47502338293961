
@mixin userSelect($value) {
    -webkit-user-select: $value;  /* Chrome all / Safari all */
    -moz-user-select: $value;     /* Firefox all */
    -ms-user-select: $value;      /* IE 10+ */
    user-select: $value;          
}

// Media query
@mixin media($size) {
    @if $size == mobile {
        @media (max-width: 600px) { @content; }
    } @else if $size == tabletDown {
        @media (max-width: 767px) { @content; }
    } @else if $size == tabletUp {
        @media (min-width: 768px) { @content; }
    } @else if $size == tabletOnly {
        @media (min-width: 768px) and (max-width: 1024px) { @content; }
    } @else if $size == desktopDown {
        @media (max-width: 1024px) { @content; }
    } @else if $size == desktop {
        @media (min-width: 1024px) and (max-width: 1200px) { @content; }
    } @else if $size == desktopUp {
        @media (min-width: 1200px) { @content; }
    } @else if $size == LargeDesktopDOwn {
        @media (max-width: 1200px) { @content; }
    } 
}

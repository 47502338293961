.manuals-h6{
    font-size: 1.5rem;
    line-height: 110%;
    margin: .5rem 0 .4rem 0 !important;
}
.manuals-h4{
    font-size: 2.28rem;
    line-height: 110%;
    margin: 1.14rem 0 .912rem 0;
    color: #000000;
    font-weight: 400;
    letter-spacing: 1.6px;
}
.chapter-header {
    border-bottom: 2px solid;
    
}


.faqs-nav-category {
    margin-top: 10px;
  margin-bottom: 20px;
}
.section-part {
    margin-bottom: 30px;
}
.chapter-sidebar-title {
    display: inherit;
    font-weight: 500 !important;
}
.section-link {
    color: #2ba0ff !important;
}
.section_title{
    text-decoration: none;
    color: inherit;
}
.span-style {
    display: inherit;
        a{
            text-decoration: underline ;
            font-weight: 400 ;
          }      
}
.manual-title {
    line-height: 26px;
    color: #2ba0ff ;
    letter-spacing: 1.6px;
    font-size: calc(24px * 1.05);
}
.bold {
    font-weight: 700;
}
.faq-row .col {
    float: left;
    box-sizing: border-box;
    padding: 0 .75rem;
    min-height: 1px;

}
@media only screen and (min-width: 993px) {
.faq-row .col.l4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
}
}

@media only screen and (min-width: 993px) {
.faq-row .col.l8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
}
}


.faq-row {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}
.faq-content-full {

    margin-top: 90px;
}

.faqs-content {
    padding-left: 1.5%;
}

@media only screen and (min-width: 993px) {
.faq-row .col.l12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
}
}


.faqs-content {
    padding-left: 1.5% !important;
}

@media only screen and (min-width: 993px) {
.faq-container {
    width: 70% !important;
}
}

.terms-title{

    font-weight:550;
    font-size: 1.3rem;
}

.terms-container{
    width: 100%;
    text-align: justify;
    letter-spacing: 0.6px;
}

.manual-description{
    margin-top: 20px;
    color: #090909c9;
    font-size: 1rem;
  
}

.terms-heading {
    font-weight: 600 ;
    line-height: 4.5em ;
    font-size: 24px;
}

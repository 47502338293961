
.file-upload {
    text-align: center;

    .upload-btn {
        position: relative;
        height: 150px;
        width: 150px;
        border-radius: 50%;
        border: 1px solid $light-gray;
        display: block;
        margin: auto;
        margin-bottom: 15px;
        z-index: 100;

        input {
            position: absolute;
            z-index: -1;
            visibility: hidden;
        }

        img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            position: absolute;
            left: 0;
        }

        &:hover {
            .overlay {
                opacity: 1;
                visibility: visible;
                z-index: 10;
            }
        }

        .overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background-color: #0000004a;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s ease;
            z-index: -1;
            text-align: center;
            cursor: pointer;
            @include userSelect(none);

            svg {
                margin-top: calc(50% - 15px);
            }
        }
    }
}

@import "./colors";

$header-link-border-color: rgba(34, 36, 38, 0.1);

.clear-both {
  clear: both;
}

@mixin headerMenu($size) {
  @include media($size) {
    .toggle-menu-button {
      display: block;
    }

    .menu.side-menu {
      position: fixed;
      top: 60px;
      left: -100%;
      height: 100%;
      width: 285px;
      overflow-y: auto;
      background-color: $white;
      box-shadow: 5px 12px 20px 5px rgba(0, 0, 0, 0.1);
      transition: left 0.3s ease;
      display: block;

      &.open {
        left: 0;
      }

      .menu-item {
        border: none;
        margin: 0;
        margin-top: auto;

        .header-link {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 15px;

          .anticon {
            margin-right: 10px;
          }

          .link-text {
            font-size: 16px;
            line-height: 22px;
            margin-left: 10px;
          }
          .count-item {
            position: relative;
            top: 0px;
            right: 0px;
            margin: auto 10px;
          }
        }

        &.user-detail {
          padding: 15px;
          text-align: center;

          .user-avatar-img,
          .user-first-char {
            margin: auto;
            margin-bottom: 15px;
          }

          .name {
            text-align: center;
            padding: 0;
          }
        }

        // a:hover {
        //     background-color: #f5f4f4;
        // }
      }
    }
  }
}

header {
  position: sticky;
  top: 0;
  background-color: $white;
  box-shadow: 0px -6px 15px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  height: 60px;

  .wrapper {
    position: relative;
    height: 60px;
  }

  .toggle-menu-button {
    display: none;
    cursor: pointer;
    margin-left: 10px;
    @include userSelect(none);

    svg {
      height: 30px;
    }
  }

  .logo {
    img {
      width: 180px;
    }
  }

  .adv_search {
    position: absolute;
    top: 50px;
    left: 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.1);
    min-width: 400px;

    @include media(tabletDown) {
      top: 40px;
      min-width: 250px;
    }

    .adv_search_close {
      font-size: 15px;
      position: absolute;
      right: 8px;
      top: 5px;
      user-select: none;
      cursor: pointer;
    }

    .adv_input_block {
      padding: 15px;
      padding-top: 35px;
    }

    .actions {
      text-align: right;
      margin-top: 15px;
      padding: 15px;
      border-top: 1px solid #ccc;

      .clear-filter {
        text-decoration: underline;
        color: #777;
        user-select: none;
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .error-container {
      margin: 10px 0;
      text-align: center;
      color: #f34444;
    }
  }

  .adv_search_btn {
    width: auto;
    margin-left: 1%;
    display: inline-flex;
    align-items: center;

    .adv-link {
      color: #2a75ca;
      width: 100%;
      cursor: pointer;
      vertical-align: super;

      svg {
        height: 22px;
      }
    }
    .adv-plus {
      width: 100%;
      cursor: pointer;
      padding-top: 5%;
    }

    .ant-badge-dot {
      top: 6px;
      height: 10px;
      width: 10px;
      background-color: #13cc59;
    }
  }

  .menu {
    line-height: initial;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-end;

    .menu-item {
      position: relative;
      font-size: 16px;
      transition: all 0.3s ease-in-out;

      .pro-button {
        background-color: $primary-color;
        border-radius: 50px;
        color: white;
      }

      .pro-link {
        padding: 15px 0px;
      }

      &.user-dropdown {
        padding: 10px 0px 10px 10px;

        &.user-PROVIDER .ant-avatar-circle {
          border: 2px solid $primary-color;
          border-left: 2px solid $white;
          border-right: 2px solid $white;
        }

        &.user-CONSUMER .ant-avatar-circle {
          border: 2px solid $secondary-color;
          border-left: 2px solid $white;
          border-right: 2px solid $white;
        }
      }

      .user-first-char {
        background-color: $gray80;

        &:hover {
          opacity: 1;
        }

        .ant-avatar-string {
          font-size: 18px;
          padding: 0;
          display: block;
          padding-top: 3px;
          font-weight: bold;
        }
      }

      .user-avatar-img,
      .user-first-char {
        height: 40px;
        width: 40px;
        padding: 0px;
        display: block;
        cursor: pointer;
        @include userSelect(none);
      }

      a,
      .header-link {
        display: block;
        padding: 21px 17px;
        color: $dark-text-color;
        transition: opacity 0.3s ease;
        border: none;

        &:hover,
        &:focus,
        &:active {
          opacity: 0.9;
        }
      }

      .header-link {
        text-align: center;
        padding: 0px 10px;
        color: #444;
        text-decoration: none;

        .anticon {
          font-size: 20px;
        }

        .link-text {
          font-size: 12px;
        }

        .count-item {
          position: absolute;
          top: 2px;
          right: 5px;
          background: $primary-color;
          color: #fff;
          font-size: 12px;
          text-align: center;
          z-index: auto;
          min-width: 20px;
          height: 20px;
          padding: 0 6px;
          font-weight: normal;
          line-height: 20px;
          white-space: nowrap;
          border-radius: 10px;
        }
      }

      &.active {
        background: #444;
        color: #fff;
        // border-top: 4px solid #fff;

        .header-link {
          color: #fff !important;
        }
      }

      .disableLink {
        pointer-events: none;
      }

      .enableLink {
        pointer-events: visible;
      }
    }
  }

  @include media(desktopUp) {
    .logo {
      width: 180px;
    }
  }

  @include headerMenu(LargeDesktopDOwn);
}

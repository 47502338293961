@import "../../styles/colors";

.data-card{
    margin-bottom: 25px;
    box-shadow: 0px 0px 5px 0px #3a393977;
    -webkit-box-shadow: 0px 0px 5x 0px #3a393977;
    overflow: hidden;
}

.button-style{
    width: 200px !important;
    margin : 5px auto !important;
    button{
        width: 200px !important; 
    }
}

.description::-webkit-scrollbar {
    width: 0.3rem;
}
   
.description::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    outline: 1px solid $primary-color;
}
  
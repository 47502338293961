$primary-color: #ff4d6d;
$primary-dark-color: #ff2149;
$secondary-color: #ff9f0d;

$default-bg-color: #c1c1c1ab;
$default-color: #444;
$default-dark-bg-color: #afafafab;
$default-btn-bg-color: #dadada;
$default-btn-bg-hover-color: #d2cfcf;
$default-btn-color: #000000a6;
$default-input-focus-border-color: #40a9ff;

$dark-text-color: rgba(0, 0, 0, 0.87);

// info-message
$default-info-message-bg: #f8ffff;
$default-info-message-color: #276f86;
$default-info-message-border-color: #a9d5de;

// Common colors
$white: #fff;
$light-gray: #ddd;
$gray80: #ccc;
$border-light-gray: #e8e8e8;

$error-color: #e21515;

// Social color
$facebook-bg: #3b5998;
$google-bg: #dd4b39;
$linked-bg: #1f88be;

@import "../styles/colors";

.page_not_found {
  text-align: center;
  margin-top: 15%;

  button {
    background-color: $primary-color !important;
    border-color: $primary-dark-color !important;
  }

  button:hover {
    background-color: $primary-color !important;
    border-color: $primary-dark-color !important;
  }
}

.section-top-margin {
  margin-top: 40px;
}

.section-heading-main-mobile {
  font-size: 2.3rem;
  font-weight: 500;
  color: white;
}

.section-content-main-mobile {
  font-size: 1.1rem;
  letter-spacing: 0.07rem;
  word-spacing: 0.2rem;
  color: white;
}

.tring-tring {
  color: $primary-color;
}

.mobile-footer-image {
  background: url("../images/landingPage/mobile/Source-15.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.top-wave {
  color: rgb(249, 216, 222);
}

.bottom-wave {
  color: #f6f6f6;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.section-link {
  font-size: 0.9rem;
  font-weight: bold;

  .home-page-link {
    color: #000;
    opacity: 0.6;
    margin: 0 10px;
  }

  .sign-in-button {
    font-size: 1.3rem !important;
    line-height: 1.3rem;
    background-color: $primary-color;
    border-radius: 50px;
    color: white;
    padding: 5px 25px;
    position: relative;
    margin: auto 0;
    opacity: 0.8;
  }

  .sign-in-button:hover {
    opacity: 1;
    background-color: $primary-color;
    color: white;
  }
}

.cloud-img {
  width: 45vw;
}

.sign-up-button-container {
  color: #000;
  font-size: 14px !important;

  .sign-up-partner-button {
    border-radius: 50px;
    color: white;
    padding: 0 1rem;
    margin: 0 10px 0px 0px;
    opacity: 0.8;

    &:hover {
      color: white;
      opacity: 1;
    }
  }
  .sign-up-button {
    border: 2px solid #c1395f;
    border-radius: 50px;
    padding: 0 1rem;
    opacity: 0.8;

    &:hover {
      color: #000;
      opacity: 1;
      border: 2px solid #c1395f !important;
    }
  }

  .sign-up-partner-button-mobile {
    border-radius: 50px;
    border: 1px white solid;
    color: white;
    padding: 0rem 0.5rem;
    background-color: transparent;
  }

  .sign-up-button-mobile {
    border: 2px solid #c1395f;
    border-radius: 50px;
    padding: 0rem 0.5rem;
    color: $primary-color !important;
  }
}

.logo-img {
  width: 180px;
  height: "auto";
}

.landing-search {
  width: 80%;
  margin-bottom: 1rem;

  .search-icon {
    color: #ff4d6d !important;
    background-color: #f0bec7 !important;
  }

  .ant-input-group .ant-input {
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    position: relative;
    border: 0.5px solid #f0bec7;
    border-right: none;
  }

  .ant-input-search-button {
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    border-left: none;
  }

  .ant-input-group-addon {
    background-color: transparent;
  }
}

.mobile-bg {
  background: url("../images/landingPage/mobile/source-01.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.section-heading {
  font-size: 1.2rem;
  font-weight: 700;
}

.section-content {
  font-size: 0.9rem;
}

@media (min-width: 576px) {
  .section-heading {
    font-size: 1.5rem;
  }
  .section-content {
    font-size: 1rem;
  }
}
@media (min-width: 992px) {
  .section-link {
    font-size: 1rem;
  }
  .section-heading {
    font-size: 2rem;
  }
  .section-content {
    font-size: 1.2rem;
    letter-spacing: 0.08rem;
    word-spacing: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .section-link {
    font-size: 1.1rem;
    .home-page-link {
      margin: 0 15px;  
    }
  }
  .section-heading {
    font-size: 2.5rem;
  }

  .section-content {
    font-size: 1.35rem;
    letter-spacing: 0.1rem;
    word-spacing: 0.3rem;
  }
}
@media (min-width: 1600px) {
  .section-link {
    font-size: 1.2rem;
    .home-page-link {
      margin: 0 20px;  
    }
  }
  .section-heading {
    font-size: 3rem;
  }

  .section-content {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    word-spacing: 0.3rem;
  }
}

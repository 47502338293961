@import "../../styles/_colors.scss";

.selected-type {
  border       : 1px solid $primary-color !important;
  border-radius: 4px;
}

.product-form {
  label {
    font-weight: 600;
    color      : #000000A6;
  }

  .section-heading {
    font-size: 16px !important;
  }
}
@import "../../../styles/colors";
@import "../../../styles/function";

.dashboard-services {
  .pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    li {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      a {
        text-decoration: none;
        color: $primary-color;
        outline: none;
      }
    }
  }

  .add-service {
    margin-bottom: 15px;

    .title {
      font-size: 25px;
      font-weight: bold;
    }

    .status-filter {
      width: 120px;
      margin-left: 10px;
    }

    button {
      float: right;
    }

    .represent {
      width: 150px;
      height: 150px;
      margin: 0px auto 10px auto;
    }
  }

  .no-service-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 60px 0;

    svg {
      height: 70px;
      display: block;
    }
  }

  .filter {
    margin-bottom: 15px;
  }

  .service-list {

    .service-item {
      margin-bottom: 25px;
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0px 0px 5px 0px #3a393977;
      -webkit-box-shadow: 0px 0px 5x 0px #3a393977;

      .image {
        width: 100%;
        height: auto;
        border-radius: 5px;
        margin-bottom: 16px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      p {
        margin-bottom: 0.2rem;
      }

      .content {
        ul {
          list-style-type: none;
          padding: 5px 20px;
        }
      }

      .actions {
        padding: 10px 5px;

        button {
          width: 100%;
          max-width: 200px;
          text-align: center;
        }
        a {
          color: rgba(0, 0, 0, 0.65);
        }
        .delete {
          width: 100%;
          max-width: 200px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.word-break {
  text-overflow: ellipsis;
  overflow: hidden;
}

.review-and-rating {

    .title-block {
        margin-bottom: 25px;
        padding: 25px 0;
        border-bottom: 1px solid #ccc;

        .back-btn {
            margin-right: 20px;
        }

        .title, .anticon-message {
            font-size: 25px;
            font-weight: bold;
        }

        .anticon-message {
            margin-right: 10px;
        }
    }

    .rating-form {

        .rating-block {
            margin-bottom: 20px;
            
            label {
                font-weight: bold;
                margin-right: 10px;
            }
            
            textarea {
                width: 100%;
                min-height: 100px;
            }
        }

        .form-action {
            text-align: right;
        }

        .form-error {
            text-align: center;
            margin: 20px 0px;
            color: #e5422a;
        }
    }

}
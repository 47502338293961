@import '../../styles/colors';

.search-banner-slider {
  min-height: 150px;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;

  .banner-item {
    background-color: #C1395F;
    min-height: 150px;

    img {
      width: 100%;
      height: 220px;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .slick-dot-item {
      height: 15px;
      width: 15px;
      border: 2px solid #fff;
      border-radius: 50%;
      margin: 0 10px;
      background-color: transparent;
      transition: background 0.3s ease-out;
    }

    .slick-active .slick-dot-item {
      background-color: #fff;
    }
  }
}

.banner-style1 {
  font-size: 30px;
  font-weight: 600;
  color: white;
}

.banner-msg-style1 {
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
  color: white;
}

@media (max-width: 576px) {
  .banner-style1 {
    font-size: 20px;
  }
  .banner-msg-style1 {
    font-size: 14px;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 16px;
}
.ant-tabs-tab-btn {
   font-size: 16px;
}


.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  width:180px;
  justify-content: center;
}

.ant-tabs-content-holder {
  padding: 8px;
}
.footer_sec{
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
}

.mid-sec{
    width: 100%;
}

.section-theme {
    padding: 40px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.section_1{
    background-color: #fff;
}

.mid-sec .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.mid-sec .img-container {
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    padding: 1%;
}

.mid-sec .align-items {
    align-items: center!important;
}

.mid-sec .img-container img {
    width: 100%;
    max-height: 450px;
}

.mid-sec .ty-body-text{
    font-size: 16px;
    line-height: 1.7;
    color: #000;
}

.mid-sec img svg {
    vertical-align: middle;
}

.mid-sec img {
    border-style: none;
}

.description{
    width: 100%;
}
.mid-sec .description p {
    margin-bottom: 1.7rem;
}

.mid-sec p {
    margin: 0 0 1em;
    line-height: 1.4285em;
}

.mid-sec-div{
    margin: 0 0 1em;
}

.mid-sec .ty-read{
    margin-top: 1.5rem!important;
}

.mid-sec .ty-link {
    color: #2b6def;
    text-decoration: none;
    font-size: 16px;
    line-height: 25px;
    font-weight: 700;
    border: none;
}

.section_2{
    background-color: #f1f3f6;
}

.section_4{
    background-color: #2c6def;
}

.sub_card{
    background-color: #0a1647 !important;
}

.section_5{
    background-color: #2c6def;
}

.footer {
    padding: 30px 30px;
}

.footer{
    background-color: #c1395f;
}

.footer img{
    // height: 32px;
    // width: 131px;
    height: 100%;
    width: 100%;
}

.footer .m_order_2 {
    order: 2;
    text-align: left;
}

.footer .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.footer .text-white {
    color: #fff!important;
}

.footer h3 {
    font-size: 16px;
}

.footer ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
}

.footer ul li {
    margin: 5px 0;
    text-transform: capitalize;
}

.footer .footer-link {
    color: #ffffff;
    font-size: 16px;
    line-height: 30px;
}

.footer-link-sm{
    font-size: 12px !important;
}

.footer .a {
    text-decoration: none;
}

.copyRights{
    width: 100%;
    text-align: center;
    background-color:transparent;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
}

.footer_2{
    width: 100%;
    text-align: center;
    background-color: #fff;
    color: #504343;
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    padding-top: 1%;
}

.footer_2 img{
    width: 125px;
    margin: 10px;
}

.col-md-12{
    width: 100%;
}
.rcorners1 {
    border-radius: 25px;
 }

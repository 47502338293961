.provider-layout {

    .title {

        .status-filter {
            width: 120px;
        }

    }

    .content-container {
        margin: 16px;

        .sub-content-container {
            min-height: 360px;
            max-width: 1128px;
        }
    }
}
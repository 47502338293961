.contact-container {
    margin: auto;
    padding: auto;
    max-width: 600px;
}

.contact-section {
    background: #f7f9fb;
    box-shadow: 9px 13px 45px 4px rgba(204,213,222,.24), -9px -13px 45px 4px rgba(81,103,124,.24);
    padding: 50px;
    margin: 40px auto 0;
    max-width: 500px;
    
}
.first-name-section {
    padding-right: 20px;
}

.name-sub-section {
    display: flex;
    flex-direction: row;
}

.email-sub-section{
    padding: 5px 0;
}
.description-sub-section {

    padding: 5px 0;
}

.number-sub-section {
    padding: 5px 0;
}

.button-container {
    text-align: right;
}

.form-error {
    color: red;
    margin-bottom: 10px;
}
@import "../../../styles/colors";
@import "../../../styles/function";

$borderColor: #e8e8e8;

.message-border{
    border: 1px solid $borderColor;
    min-height: 100%;
    height: 500px;
}

.message-block {

    height: 100%;

    .bg-white{
        background: #e4e4e4;
        box-shadow: none;
        z-index: 5;
    }

    .msg-container{
        padding: 10px 10px;
    }
    .avatar{
        text-align: center;
    }
    .name{
        text-align: center;
        font-size: 20px;
        text-transform: capitalize;
    }

    .message {
        display: block;
        min-width: 100px;
        height: auto;
        padding: 8px 10px;
        border-radius: 5px;

    }
    
    .text{
        color: #01110e;
        word-wrap: break-word;
        hyphens: auto;
    }

    .time-sender{
        color: #aaa;
        font-size: 10px;
        margin-bottom: 8px;
        margin-left: 25px;
    }
    
    .time-user{
        color: #aaa;
        font-size: 10px;
        margin-bottom: 8px;
        margin-right: 25px;
        text-align: right;
    }

    .left{
        float: left;
        max-width: 45%;
    }

    .right{
        float: right;
        max-width: 45%;
    }

    .sender{
        background-color: #86ddc9;
        display: block;
        margin-left: 25px;
        position: relative;
    
        &:before{
            content: "";
            border: 5px solid transparent;
            border-right-width: 30px;
            border-right-color: #86ddc9;
            border-left: 0;
            position: absolute;
            bottom: -5px;
            left: -25px;
            transform: rotate(340deg);
        }
    }

    .user{
        background-color: #7cc1fa;
        display: block;
        margin-right: 25px;
        margin-left: auto;
        display: flex;
        position: relative;

        &:after{
            content: "";
            border: 5px solid transparent;
            border-right-width: 30px;
            border-right-color: #7cc1fa;
            border-left: 0;
            position: absolute;
            bottom: -5px;
            right: -25px;
            transform: rotate(200deg);
        }

    }

    .clear-both {
        clear: both;
    }

    .send-message {
    
        border-top: 1px solid $borderColor;
        padding: 5px 5px;
    
        .form{
            display: flex;
    
            input, button {
                border: 0;
            }
    
            button, button:focus,
            button:hover {
                color: $primary-color;
            }
    
            .ant-input:focus {
                box-shadow: none;
            }
        }
    }

}

.contact-list-block {

    height: 100%;

    .contact-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        border-top-left-radius: 10px;

        li {
            border-bottom: 1px solid $borderColor;
            padding: 15px 10px;
            cursor: pointer;
            transition: all .3s ease-in-out;
            background-color: #fff;
            &.active, &:hover {
                background-color: #eeeeee;
            }

            .avatar{
                text-align: center;
            }
            .name {
                text-align: center;
            }

            .unread-bubble{
                height: 26px;
                width: 26px;
                border-radius: 13px;
                padding: 5px;
                font-size: 13px;
                background-color: #2bdf7c;
                color: #fff;
                float: right;
                text-align: center;
            }

        }

    }
    .search-box{
        border-bottom: 1px solid $borderColor;
    
        .name{
            color : #fff;
        }

    }
}

.msg_alert{
    width: 35%;
    margin: 0px auto;
    margin-bottom: 2%;

    .concern_box{
        background-color: rgba(255, 163, 158, 0.4) !important;
    }
}

@include media(tabletDown) {

    .message-border {
        min-height: 500px;
        height: 500px;
    } 
    .message-block{
        .left{
            max-width: 75%;
        }
        .right{
            max-width: 75%;
        }
    }

    .ant-layout-header{
        padding: 0 30px;
    }

}
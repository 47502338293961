.seeker-layout {
    margin-top: 0px;

    .image-title {
        width: 100%;
        height: 150px;
    }

    title {
    
        .status-filter {
            width: 120px;
            margin-left: 10px;
        }

    }
}

.m-r-1 {
    margin-right: 1px
}

.card {

    .button-style {
        margin: 2px;
        min-width: 200px;
    }
}

@media(max-width : 768px){
    .seeker-layout{
        .content-container{
            .sub-content-container{
                padding: 5px;
            }
        }
    }
}
  
@media (min-width: 769px) and (max-width: 991px){
    .seeker-layout{
        .content-container{
            .sub-content-container{
                padding: 15px;
            }
        }
    }
}
@import "../node_modules/antd/dist/antd.css";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "./styles/colors";

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #151211dc !important;
}

a {
  color: #0170fe;
  text-decoration: none;
}
a:link {
  color: #0170fe;
  text-decoration: none;
}
a:hover {
  color: #0170fe;
  text-decoration: none;
}

.ui.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1rem 0;
  background: #fff;
  font-weight: 400;
  border: 1px solid rgba(34, 36, 38, 0.15);
  -webkit-box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  min-height: 2.85714286em;
  background: #fff !important;
}

.homepage {
  position: relative;
}

.footer {
  width: 100% !important;
}

.gs-card-btn {
  align-self: center !important;
  margin-top: 2% !important;
}

.subSection .ui.input {
  min-width: 300px;
}

.ui.selection.dropdown .menu > .item {
  border-top: 1px solid #fafafa;
  padding: 0.78571429rem 1.14285714rem !important;
  white-space: normal;
  word-wrap: normal;
  background: white !important;
}

.card-footer {
  text-align: right !important;
}

.ui.form .field > label {
  color: rgba(0, 0, 0, 0.75) !important;
}

.ui.card > .content > .header {
  color: rgba(0, 0, 0, 0.75) !important;
}

.error-msgs {
  font-size: 20px !important;
  color: crimson !important;
}

.error-small-msgs {
  font-size: 12px !important;
  color: crimson !important;
}

.ui.menu .item > a:not(.ui),
.ui.menu .item > p:only-child,
.ui.menu .text.item > * {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  line-height: 1.3;
  color: #000 !important;
}

.error-container {
  width: 100%;
  margin: 10px 0px;
}

#__search {
  .ant-input-group .ant-input {
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    position: relative;
    border: 0.5px solid $primary-color;
    border-right: none;
    min-width: 80%;
  }
  .ant-input-search-button {
    background-color: $primary-color;
    border-color: $primary-color;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    border-left: none;
    color: #fff;
  }
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border-color: $primary-color;
}
.image-gallery-icon:hover {
  color: $primary-color;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 50px;
  width: 30px;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #f5f5f5 !important;
  color: rgba($color: #000000, $alpha: 0.25) !important;
}
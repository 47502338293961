.addon-filter{
    width: 100px;
}
.addon-title{
    margin-right: 20px;
}

.addon{
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #3a393977;
    -webkit-box-shadow: 0px 0px 5x 0px #3a393977;
    height: 100%;
}
.pointer{
    cursor: pointer;
}
@import "../../../styles/colors";
@import "../../../styles/function";

.checkout {

    min-height: 500px;

    .checkout-steps {
        margin: 15px 0;

        .ant-steps-item-finish {

            .ant-steps-item-icon {
                border-color: $primary-color;

                & > .ant-steps-icon {
                    color: $primary-color;
                }

            }

            & > .ant-steps-item-content > .ant-steps-item-title::after {
                background-color: $primary-color;
            }
        }

        .ant-steps-item-process {
            .ant-steps-item-icon {
                background-color: $primary-color;
                border-color: $primary-color;
            }
        }
    }

    .order-detail {
        background-color: #fff;

        .checkout_addon_item {
            border: 1px solid $gray80;
            border-radius: 3px;
            margin-bottom: 10px;
            label {
                margin-left: 10px;
            }
        }

        ul.order-detail-ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            & > li {
                padding: 15px;
                border: 1px solid $gray80;
                border-radius: 3px;
                // background-color: #1273cce3;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                // color: #fff;

                .c-head {
                    width: 100%;
                }

                .item {
                    position: relative;
                    width: 100%;
                    display: flex;
                    padding-right: 0px;
                
                    .img-block {
                        height: 100px;
                        width: 150px;
                        border: 1px solid $gray80;
                        display: inline-block;

                        img {
                            width: 150px;
                            height: 100px;
                        }
                    }
                    .description {
                        padding-left: 15px;
                    }

                    .base-price {
                        width: 100px;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 0;
                    }
                }


                @include media(tabletDown) {
                    .item {
                        padding-right: 0;
                        display: block;
                    
                        .img-block {
                            margin: auto;
                            display: block;
                            margin-bottom: 15px;
                        }

                        .description {
                            padding-left: 0;
                            margin-bottom: 15px;
                        }
    
                        .base-price {
                            position: inherit;
                            float: none;
                            width: auto;
                            text-align: right;
                        }
                    }
                }

                .contract-info{
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    width: 100%;

                    span{
                        margin-right: 2px;
                    }

                    .c_id{
                        color: black;
                    }
                    .title{
                        width: 100px;
                        text-overflow: ellipsis;
                    }
                }


                .title{
                    width: 100px;
                    text-overflow: ellipsis;
                    margin-bottom: 0px;
                    flex-grow: 1;
                }

                .checkbox{
                    margin-right: 20px;
                }
            }
        }

    }

    .payment-detail {
        padding: 15px;
        
        ul.order-ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            & > li {
                padding: 10px 0;

                .item-detail {
                    float: right;
                    font-weight: bold;
                }
            }

            & > li.line {
                border-top: 1px solid $gray80;
                content: '';
                padding: 0;
                margin: 5px 0;
            }
        }

        .action {
            text-align: center;

            .btn-order {
                width: 100%;
            }

            .btn-add-address{
                margin-top: 5px;
            }

            b{
                color: #ec3c4c;
            }
        }

        @include media(tabletDown) {
            margin-top: 25px;

            .action .btn-order {
                max-width: 250px;
            }
        }
    }

    .back-to-contract {
        margin-right: 15px;

        &:hover, &:focus, &:active {
            color: #444;
            border-color: $gray80;
        }
    }

    .payment-success {
        padding: 15px;
        border: 1px solid $gray80;
        background-color: $white;

        .detail {
            text-align: center;
            margin: 50px 0;

            i svg {
                width: 150px;
                height: 150px;
                margin-bottom: 10px;
                color: $primary-color;
            }
        }
    }
}

.margin-auto {
    margin: auto;
}

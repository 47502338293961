.saved-card {
    position: relative;
    width: 350px;
    margin: auto;
    border: 1px solid $gray80;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.2);
    
    .saved-card-number {
      margin: 20px 0px;
  
      span::after {
        content: '-';
        margin: 0 19px;
      }
  
      span:last-of-type::after {
        content: '';
        margin: 0;
      }
    }
  
    .card-action {
      text-align: right;
  
      button {
  
        &:hover,
        &:focus,
        &:active {
          color: $primary-color;
          border-color: $primary-color;
        }
      }
    }
  
    @include media(mobile) {
      width: 270px;
  
      .saved-card-number {
        span::after {
          content: '-';
          margin: 0 5px;
        }
      }
    }
  }
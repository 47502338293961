@import "../../../styles/colors";
@import "../../../styles/function";

.myorder-list {


	.sort-order {
		margin-bottom: 15px;

		.title {
			font-size  : 25px;
			font-weight: bold;
		}

		.order-status-filter {
			width: 200px;
		}

		button {
			float: right;
		}
	}

	.no-agreement-found {
		color          : $gray80;
		display        : flex;
		align-items    : center;
		justify-content: center;
		flex-direction : column;
		margin         : 60px 0;

		svg {
			height : 70px;
			display: block;
		}
	}

	.order-list {
		margin         : 0;
		padding        : 10px 5px;
		list-style-type: none;
	}
}

@media (min-width: 992px) {
	.myorder-list{
		width: 900px;
		margin: 0 auto;
	}
}

.cancel-order-model {
	width: 100%;

	.operation_type {
		padding    : 5%;
		text-align : center;
		font-size  : 16px;
		font-weight: 400;
	}

	.form-error {
		color        : red;
		margin-bottom: 10px;
	}
}
@import '../../styles/colors';

.htw_container {
  width: 100%;
  height: 100%;

  .s_row {
    background-color: #fefefe;
  }
  h3 {
    font-weight: bold !important;
    font-size: 24px;
  }
}
.how-it-works-title{

  font-size: 30px;
  font-weight: 600;
  color: 	#2E8B57;
  padding: 10px 10px 0 20px;

}

.cns-jumbotron {
  margin-bottom: 2rem;
  text-align: center;
  background-image: url(../../images/banners-4.png);
  background-size: 100% 100%;
  background-position: -29%;
  background-repeat: no-repeat;
}

.overlay{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.433);
  padding: 2rem 1rem;
}

.slider-img {
  height: 100%;
    min-height: 300px;
    padding: 0;
    margin: auto;
    width: 200px;
 
}

.how-it-work-para {
  font-size: 20px;
  font-weight:500 !important;
  line-height: 2rem;
  padding: 0 10px 20px 50px;
}

.how-it-works-container{
  padding: 10px 0 40px 0 !important;
}

.mid_heading {
  text-align: center;
  
  padding-bottom: 40px;

  h1 {
    font-size: 50px;
    font-weight: bold !important;
  }
  hr {
    width: 10%;
    border: 2px solid $primary-color;
  }
}

.how-it-works-box{
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

@media(min-width : 576px){
  .overlay {
      padding: 4rem 2rem;
  }
}

@media (min-width: 769px){
  .overlay {
      padding: 6rem 2rem;
  }
}
@import "../../styles/function";
@import "../../styles/colors";

.auth-wrapper {
  margin-bottom: 30px;

  .description {
    padding: 20px;
    font-size: 18px;
    margin: 0;
    text-align: center;
    font-weight: bold;
  }

  .form-wrapper {
    background-color: $white;
    margin-top: 1.5%;
    box-shadow: 0px 3px 23px -7px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 15px;

    .title {
      padding: 15px;
      margin: 0;
      background-color: $primary-color;
      color: $white;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    .form-content {
      padding: 20px;

      .google-captcha {
        margin-bottom: 15px;
      }

      .form-error {
        margin: 15px auto;
        text-align: center;
        color: $error-color;
        transition: all 0.2s ease;
      }
    }

    .btn-primary {
      width: 150px;

      &.btn-signup-pro {
        width: 200px;
      }
    }

    @include media(mobile) {
      .btn-primary,
      .btn-signup-pro,
      .btn-default {
        width: 100%;
      }
    }
  }

  .other-options {
    margin-top: 20px;

    .social-btn {
      margin-left: 10px;
    }

    .social {
      margin-bottom: 20px;
    }

    .btn-facebook {
      margin: 0;
    }

    @include media(mobile) {
      .social-btn {
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
      }
    }
  }

  .link {
    color: #fff;
    margin-left: 10px;
  }
}

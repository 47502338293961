.main-container {
    width: 100%;
    height:100%;
}

.jumbotron {
    margin-bottom: 2rem;
    text-align: center;
    background-image: url(../../images/banners-1.png);
    background-size: 100% 100%;
    background-position: -29%;
    background-repeat: no-repeat;
    padding: 0;
}

.section-title{
    text-align: center;
    margin-bottom: 40px;
}

.section-content {
    color: #090909c9;
}

.section2-container {
    padding: 60px 10px 100px;
    background-color: #f2f3f2;
}

.section3-container {

    padding-bottom: 100px;
    padding-top: 60px;
    background-color: white;
}

.overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.241);
    padding: 2rem 1rem;
}

.img-title {
    font-size: 3.5rem;
    font-weight: 500;
    color: white;
    transform: translate(-50%, -50%);
}


.section-span{

    font-size: 30px;
    font-weight: 500;
    line-height: 3.2rem;
}


@media (max-width: 575px){

}

@media(min-width : 576px){
    .overlay {
        padding: 4rem 2rem;
    }
}

@media (min-width: 769px){
    .overlay {
        padding: 6rem 2rem;
    }
}


.team-card {
    padding: 15px;
    border-radius: 15px;
    height: 320px;
    overflow: hidden;
    border: 1px solid #e9e7e7;
    box-shadow: 0 0 10px #00000013;

    .designation {
        margin-bottom: 10px;
        font-weight: 600;
    }

    .linked-in {
        background-color: #c1395f;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 25px;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

}
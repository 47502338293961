@import "../../styles/colors";
.s_row {
  background-color: #fefefe;
  padding: 5px;
}
.htw_container {
  width: 100%;
  height: 100%;
  h3 {
    font-weight: bold !important;
    font-size: 24px;
  }
}

.pro-jumbotron {
  margin-bottom: 2rem;
  text-align: center;
  background-image: url(../../images/banners-2.png);
  background-size: 100% 100%;
  background-position: -29%;
  background-repeat: no-repeat;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.433);
  padding: 2rem 1rem;
}

.img-title {
  font-size: 3.5rem;
  font-weight: 500;
  color: white;
  transform: translate(-50%, -50%);
}

.how-it-work-para {
  font-size: 20px;
  font-weight: 500 !important;
  line-height: 2rem;
  padding: 0 10px 20px 50px;
}

.how-it-works-container {
  padding: 10px 0 40px 0 !important;
}

.mid_heading {
  text-align: center;
  
  padding-bottom: 40px;

  h1 {
    font-size: 50px;
    font-weight: bold !important;
  }
  hr {
    width: 10%;
    border: 2px solid $primary-color;
  }
}

.how-it-works-box {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.section-heading-2 {
  font-size: 2rem;
  font-weight: 700;
}

.section-content-2 {
  font-size: 1.3rem;
  letter-spacing: 0.08rem;
  word-spacing: 0.25rem;
}

.wl-20px {
  height: auto;
  width: 35vw;
}

@media (min-width: 576px) {
  .overlay {
    padding: 4rem 2rem;
  }
}

@media (min-width: 769px) {
  .overlay {
    padding: 6rem 2rem;
  }
}

// React notification
.notification-message {
  .title {
    font-weight: bold;
    color: $white;
    font-size: 1.2rem;
  }
}

.clear-both {
  clear: both;
}

.text-center {
  text-align: center;
}

// message information
.info-message {
  background-color: transparent;
  color: $default-info-message-color;
  padding: 15px 20px;
  border-radius: 5px;
  margin-bottom: 15px;

  .desc {
    font-weight: bold;
  }
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

// ANT design override
.ant-row-flex.ant-row-flex-center {
  width: 100%;
  overflow-y: auto;
  height: 100%;
}

#root {
  height: 100%;
}

.ant-popover-inner-content {
  max-width: 400px;
}

.ant-modal {
  .ant-modal-header {
    background-color: $primary-color;

    .ant-modal-title {
      color: $white;
    }
  }

  .ant-modal-close-x {
    color: $white;
  }

  .ant-btn-primary,
  .ant-btn-primary:hover {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }

}
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}
.ant-btn:hover, .ant-btn:focus {
  border-color: $primary-color !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary-color;
}
.ant-switch-checked {
  background-color: #15c39a;
}

.ant-btn-primary:disabled {
  color: #fff !important;
  opacity: 0.6;
}
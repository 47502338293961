@import "../../styles/function";
@import "../../styles/colors";

$tab-header-hover-bg: rgba(6, 6, 6, 0.04);
$tab-item-color: rgba(0, 0, 0, 0.45);
$tab-item-hover-color: rgba(0, 0, 0, 0.65);

.profile-card {
  box-shadow: 0px 0px 3px 0px #77767677;
  -webkit-box-shadow: 0px 0px 5x 0px #3a393977;
}

.my-profile {
  
  .title {
    border: none;
    padding: 0 0;
    text-transform: none;
    color: $default-color;
    font-weight: bold;

    svg {
      float: right;
      height: 22px;
      cursor: pointer;
      @include userSelect(none);
    }
  }

  .btn-primary {
    background-color: $primary-color;
    color: $white;
    border: none;

    &:hover {
      background-color: $primary-dark-color;
      color: $white;
      opacity: 0.9;
    }
  }

  .btn-default {
    background-color: $default-bg-color;
    color: $default-color;
    border: none;

    &:hover {
      background-color: $default-dark-bg-color;
      color: $default-color;
    }
  }

  .tab-header {
    border-bottom: 1px solid $border-light-gray;

    .header {
      color: $white;
    }
  }

  .form-action {
    text-align: right;

    button.btn-submit {
      margin-left: 15px;
    }
  }

  .form-error {
    color: red;
    margin-bottom: 10px;
  }
}

.personal-detail {
  .bio {
    margin-top: 25px;
    margin-bottom: auto;
  }
  .profile-field {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .profile-item {
      label {
        font-weight: bold;
      }

      .text {
        padding-left: 10px;
      }

      .text-img {
        max-width: 300px;
        max-height: 150px;
        margin: 10px;
      }
    }
  }

  .user-first-char {
    font-size: 40px;
  }

  .upload-file-preview,
  .ant-upload {
    margin: auto;
    border-radius: 65px !important;
    width: 130px !important;
    height: 130px !important;
  }

  .upload-file-preview {
    img {
      width: 130px !important;
      height: 130px !important;
      border-radius: 65px !important;
    }
  }
}

.billing {
  animation: fadeIn 0.3s linear;
  -webkit-animation: fadeIn 0.3s linear;

  .expiration-date {
    clear: both;
    margin: 0 0 1em;

    label {
      display: block;
      margin: 0 0 5px 0;
      font-size: 14px;
      font-weight: 700;
      text-transform: none;
    }

    .group-field {
      display: flex;

      input {
        width: 80px !important;
      }

      .date-separator {
        display: block;
        padding: 10px;
      }
    }
  }

  .no-card {
    text-align: center;
    color: $gray80;
    padding: 50px 0;
    width: 55%;
    margin: 0px auto;

    svg {
      height: 45px;
    }
  }

  .tab-btn {
    float: right;

    .btn-icon {
      float: left;
      width: 30px;
    }
  }

  .select-plan {
    text-align: center;
    margin-top: 10px;
  }

  .billing-cycle {
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
  }

  .downgrade-error-message {
    color: $error-color;
    margin: 10px 0;
  }
}

.billing-modal,
.profile-modal {
  .form-error {
    color: red;
    margin-bottom: 10px;
  }
}

.profile-modal {
  .phn_verify {
    display: flex;

    .phn_verify_checkbox {
      margin-left: 15px;
      margin-top: 6px;
    }
  }
}

.address-list {
  .address-item {
    position: relative;
    padding-bottom: 35px;
    border: 1px solid $gray80;
    min-height: 125px;
    padding: 10px;
    margin: 10px;

    .anticon-check-circle {
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 20px;
      color: #4c93e5;
    }

    .action {
      position: absolute;
      left: 0;
      bottom: 5px;
      right: 0;
      text-align: center;
      z-index: 10;

      svg {
        margin: 0 5px;
        cursor: pointer;
        padding: 5px;
        color: #7b7979;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: #444;
        }
      }
    }
  }
}

.plan-details {
  .cancel-plan-update {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }

  .tab-header {
    margin-bottom: 15px;
  }

  .plan-name {
    border: 1px solid #ccc;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 30px 0;

    & > span {
      font-weight: bold;
    }
  }

  .billing-info {
    
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 1200px) {
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 0;
    }
  }

  .plan-detail-view {
    animation: fadeIn 0.3s linear;
    -webkit-animation: fadeIn 0.3s linear;

    .active-plan-detail {
      margin-top: 20px;

      table tr td span.price {
        font-size: 22px;
        font-weight: 500;
        margin: 0;
        padding: 0;
      }

      table tr td {
        font-size: 14px;
        font-weight: 400;
        color: #90a0ac;
        line-height: 22px;
        letter-spacing: 0;
        color: #022144;
        padding: 10px 15px 10px 0px;
        vertical-align: top;
      }

      table {
        width: 100% !important;
      }

      table tr td:last-child {
        padding-right: 0;
        border-right: none;
      }
    }

    ._progress {
      margin: 30px 0px 30px 20px;
      text-align: center;
    }
  }

  .plan-options {
    overflow: auto;
    animation: fadeIn 0.3s linear;
    -webkit-animation: fadeIn 0.3s linear;

    table {
      width: 100%;

      tr td {
        font-size: 14px;
        font-weight: 400;
        color: #90a0ac;
        line-height: 22px;
        letter-spacing: 0;
        color: #022144;
        padding: 10px 10px;
        border-right: 1px solid #f2f3f5;
        vertical-align: top;
      }

      tr td:last-child {
        padding-right: 0;
        border-right: none;
      }
    }
  }

  .move-plan-detail-block {
    padding: 15px;

    .summary {
      text-align: center;
    }

    .move-plan-detail-wrapper {
      display: flex;
      justify-content: center;
      margin: 20px;
    }

    .move-plan-detail {
      margin: 0 20px;

      .title {
        color: #abaaaa;
      }
    }

    .move-to {
      position: relative;
      margin-left: 20px;

      &-icon {
        position: absolute;
        top: 110px;
        left: -30px;
        z-index: 10;
      }
    }

    .action {
      text-align: center;
    }

    .move-plan-detail-cancel {
      color: #abaaaa;
      display: block;
      margin: auto;
      margin-top: 10px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .add-new-card {
    text-align: center;
  }
}

.payment-success {
  padding: 15px;
  margin-top: 5%;
  border: 1px solid $gray80;
  background-color: $white;

  .detail {
    text-align: center;
    margin: 50px 0;

    i svg {
      width: 150px;
      height: 150px;
      margin-bottom: 10px;
      color: $primary-color;
    }
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.notifications {
  .icon {
    vertical-align: middle;
    margin-right: 5px;
  }

  .ui.celled.table tr td.name {
    text-align: left;
  }

  .form-error {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .form-action {
    margin-top: 20px;
  }
}

.profile-payout-block {
  .bank-info {
    margin: auto;
    max-width: 400px;
    border: 1px solid $border-light-gray;
    padding: 15px;
    border-radius: 5px;

    .bank-item {
      margin-bottom: 5px;
      border-bottom: 1px solid $border-light-gray;
    }

    .mata-data {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        display: inline;
        margin-left: 10px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  .no-found {
    margin: 30px 0;
    text-align: center;
    color: $gray80;

    .anticon {
      font-size: 34px;
      color: $gray80;
    }
  }
}

.add-new-card {
  .tab-btn i {
    width: 30px;
  }
}

.consents {
  width: 100%;

  .section {
    margin: 5%;
    padding: 0px;
    width: auto;
  }
}

.used-contracts {
  width: 70px;
}

@media (max-width: 769px) {
  .billing_history_table {
    overflow-x: scroll;
  }
}
.used-contracts {
  width: 70px;
}

.profile-list-item {
  color: #000000a6;
}

.profile-list-item:hover {
  color: #000000a6;
}

.profile-list-item-selected {
  background-color: #f0f2f5;
  border-left: 4px $primary-color solid;
}

.profile-list-icon-selected {
  color: $primary-color;
}

.profile-list-text-selected {
  font-weight: 600;
  color: #000000a6;
}

.profile-list-text {
  color: #000000a6;
}
.ant-modal-content {
  border-radius: 15px !important;
  overflow: hidden;
}

.review-and-rating {

    .title-block {
        margin-bottom: 25px;
        padding: 25px 0;
        align-items: center;
        display: flex;

        .back-btn {
            margin-right: 20px;
        }

        .title, .anticon-message {
            font-size: 25px;
            font-weight: bold;
        }

        .anticon-message {
            margin-right: 10px;
        }
    }

    .rating-form {

        .rating-block {
            margin-bottom: 20px;
            
            label {
                font-weight: bold;
                margin-right: 10px;
            }
            
            textarea {
                width: 100%;
                min-height: 100px;
            }
        }

        .form-action {
            text-align: right;
        }

        .form-error {
            text-align: center;
            margin: 20px 0px;
            color: #e5422a;
        }
    }

}


@media (min-width: 992px) {
	.review-and-rating{
		width: 900px;
		margin: 0 auto;
	}
}